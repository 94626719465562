import barba from "@barba/core";

class Agenda {

  constructor() {

    this.debug = window.location.search == "?debug" ? true : false;
    // this.debug = true;

    window.Agenda = this;
    this.dateToScroll = null;

  }

  init() {

    let _self = this;
    // Resize Titles if they are too long
    this.titles = $('.o-events-list .o-event__title');
    if(this.titles.length) {

      $(window).on('resize', _.debounce(this._resize.bind(this), 400));
      this._resize();
    }

    $('#agendaFilterSchoolSession').on('click', function(){
        _self.filter_agenda_events();
    });

    if(_self.debug) console.log('Agenda agendaToday ?', $('#agendaToday'));
    $(document).off('click.agendaToday');
    $(document).on('click.agendaToday', '#agendaToday', function(e){
      e.preventDefault();
      e.stopPropagation();
      let dateToday = $(this).attr('data-value');
      let dateTodayMonth = dateToday.substring(0,7);
      let year_month = $('.page-template-agenda .t-agenda__filters-year-month .swiper-slide-active').attr('data-value');
      if(_self.debug) console.log('Agenda agendaToday dateToday', dateToday, 'dateTodayMonth', dateTodayMonth, 'year_month', year_month);
      if(dateTodayMonth != year_month && $('.festival-filter.tag-active').data('term_id') == 0) {
        if(_self.debug) console.log('Agenda agendaToday today is not in displayed month');

        $('.t-agenda__filters-year-month .swiper-slide').each(function(i,el){
          if ($(this).data('value') == dateTodayMonth) {
            _self.dateToScroll = dateToday;
            if(_self.debug) console.log('Agenda agendaToday changing year-month select to today\'s month slide i', i, '_self.dateToScroll', _self.dateToScroll);
            window.SelectSlider.slideTo(i);
          }
        });

      } else {
        if(_self.debug) console.log('Agenda agendaToday today might be in displayed month');
        // scroll to date or closest
        _self.dateToScroll = dateToday;
        _self.scrollToDate();

      }
    });

    if($('body').hasClass('page-template-agenda')) _self.checkIfTodayIsRelevant();

    // Manage Click On View Link To Filter Agenda
    // $('.tab').on('click', function(){
    //   // Remove Class Active
    //   $('.tab').each(function(){
    //     $(this).removeClass('tab-active');
    //   });
    //   // Active Selected Item
    //   $(this).addClass('tab-active');
    //   // Filter Agenda
    //   _self.filter_agenda_events();
    // });

    // Manage Click On Festival Link To Filter Agenda
    $('.festival-filter').on('click', function(){
      // Remove Class Active
      $('.festival-filter').each(function(){
        $(this).removeClass('tag-active');
      });
      // Active Selected Item
      $(this).addClass('tag-active');

      // Active/Desactive Month Filter
      let term_id = $(this).data('term_id');
      if (term_id == '0'){
        $('.t-agenda__filters-year-month').removeClass('hidden');
        $('.t-agenda__nav').removeClass('hidden');
      }else{
        // $('').attr('value', $('#year_month_initial').attr('value'))
        let newSlideIndex = window.SelectSlider.params.initialSlide;

        //console.log('agenda select slider hide value ?', window.SelectSlider, newSlideIndex, window.SelectSlider.params.initialSlide);
        window.SelectSlider.slideTo(newSlideIndex, 0, false);
        $('.t-agenda__filters-year-month').addClass('hidden');
        $('.t-agenda__nav').addClass('hidden');
      }
      // Filter Agenda
      _self.filter_agenda_events();
    });

    // Manage Click On Target To Filter Agenda (Danse)
    $('.target-filter').on('click', function(){
      // Remove Class Active
      $('.target-filter').each(function(){
        $(this).removeClass('tag-active');
      });
      // Active Selected Item
      $(this).addClass('tag-active');
      // Filter Agenda
      _self.filter_agenda_events();
    });

  }

  /* Generic Filter Method */
  filter_agenda_events(){
    let _self = this;

    let selected_tab = $('.tab.tab-active').data('view');
    let selected_festival_term_id = $('.festival-filter.tag-active').data('term_id');
    let active_festival_term_id = $('#active_festival_term_id').val() == '' ? 0 : $('#active_festival_term_id').val() ;

    let year_month = selected_festival_term_id != 0 && selected_tab != 'dance' ? '' : $('.page-template-agenda .t-agenda__filters-year-month .swiper-slide-active').attr('data-value');
    let zone_id = $('#agendaFilterZones').val();
    let public_slug = $('#agendaFilterPublics').val();
    let lang_id = $('#lang_id').val();

    let is_school_session = $('#agendaFilterSchoolSession').prop('checked');
    let is_festival = $('#is_festival').val();
    let selected_target = $('.target-filter.tag-active').data('term_id');

    //alert('refresh !');
    let busy;

    // Avoid Nervous Clicks !
    if ( busy )
      busy.abort();

    // Ajax Submit Form
    // console.log('SelectSlider ajax local ?', scriptsJsObject.ajaxurl.indexOf('.local'));
    if(scriptsJsObject.ajaxurl.indexOf('.local:3000') != -1) scriptsJsObject.ajaxurl = scriptsJsObject.ajaxurl.replace(/https/m, 'http');
    // console.log('SelectSlider ajax scriptsJsObject.ajaxurl ?', scriptsJsObject.ajaxurl);
    busy = $.ajax({
      type: "post",
      url: scriptsJsObject.ajaxurl,
      data: {
        action: 'mkwvs_agenda_filter',
        year_month: year_month,
        zone_id: zone_id,
        public_slug: public_slug,
        selected_tab: selected_tab,
        is_school_session: is_school_session,
        is_festival: is_festival,
        active_festival_term_id: active_festival_term_id,
        selected_festival_term_id: selected_festival_term_id,
        selected_target: selected_target,
        lang_id: lang_id },
      beforeSend: function () {
        $('.t-agenda__events-list').html(''); // Clear Content
        // $('html,body').animate({
        //   scrollTop: $(".t-agenda__landing").offset().top
        // }, 2000);

        // Show Loader ?
      },
      success: function (response) {
        //console.log('agenda response', response, response.year_month);
        // Refresh Year Month
        $('.t-agenda__events h2').html(response.current_month_year);

        if (response.success === true) {

          if (response.html_banner !== ''){ // Affichage Banniere
            $('.t-agenda__festival').replaceWith(response.html_banner);
          }else{
            $('.t-agenda__festival').html('');
          }

          if (response.display_events || response.html_banner !== ''){
            $('.t-agenda__events').removeClass('hidden');
            $('#form-filters').removeClass('hidden');

            if (response.display_events == false){
              $('.t-agenda__events').addClass('hidden');
              $('#form-filters').addClass('hidden');
            }

            // Refreshed Results From Ajax Response
            $('.t-agenda__events-list').html(response.html);
            if (is_school_session) {
              $('.t-agenda__events-list .t-agenda-item__hours ul li:not(.is-school)').hide()
            }

            // did user click on the todayDate button ? if so then scroll to it or closest
            if(_self.debug) console.log('Agenda filter_agenda_events dateToScroll ?', _self.dateToScroll);
            if(_self.dateToScroll) {
              setTimeout(function(){
                _self.scrollToDate(_self.dateToScroll);
              },150);
            }

          }else{
            $('.t-agenda__events').addClass('hidden');
            $('#form-filters').addClass('hidden');
            $('.t-agenda__events-list').html('');

          }

          // barba.history.add(url: string, trigger: Trigger)

          //console.log('year_month', year_month, 'response.year_month', response.year_month);
          if (response.hasOwnProperty("year_month") && response.year_month !== year_month){
            // $('.t-agenda__filters-year-month .swiper-slide').removeClass('is-current');
            $('.t-agenda__filters-year-month .swiper-slide').each(function(i,el){
              if ($(this).data('value') == response.year_month) {
                // $(this).addClass('is-current');
                //console.log('agenda coucou on change la slide du year month avec i = ', i);
                window.SelectSlider.slideTo(i);
              }
            });
          }
        } else {
          // No Results Feedback
          var msg_error = '<p class="error">Aucun évènement trouvé</p>';
          $('.t-agenda__events-list').html(msg_error);
        }

        let newUrl = agenda_url;
        newUrl += (year_month != '' && year_month != null) ? year_month+'/' : '';
        //newUrl += (category_id != '' && category_id != null) ? 'categories/'+category_id+'/' : '';
        newUrl += (public_slug != '' && public_slug != null) ? 'publics/'+public_slug+'/' : '';
        newUrl += (selected_tab != 'all' && selected_tab != null) ? (newUrl.indexOf('?') !== -1 ? '&' : '?')+'view='+selected_tab : '';
        newUrl += (zone_id != '' && zone_id != null) ? (newUrl.indexOf('?') !== -1 ? '&' : '?')+'zone='+$('#agendaFilterZones').find('option[value="'+zone_id+'"]').attr('data-slug') : '';
        newUrl += (typeof selected_festival_term_id != 'undefined' && selected_festival_term_id != '' && selected_festival_term_id != 0) ? (newUrl.indexOf('?') !== -1 ? '&' : '?')+'festival='+$('.festival-filter.tag-active').data('url-param') : '';
        newUrl += (typeof selected_target != 'undefined' && selected_target != '' && selected_target != 0) ? (newUrl.indexOf('?') !== -1 ? '&' : '?')+'target='+$('.target-filter.tag-active').data('slug') : '';
        // console.log('filter_agenda_events newUrl '+newUrl, barba);
        barba.history.add(newUrl, 'barba');

        // check if today button is relevant
        _self.checkIfTodayIsRelevant();

      },
      error: function (error) {
        console.error(error);
      },
      complete: _self.sortRepresentationByFirstHour
    });
    return false;
  }

  checkIfTodayIsRelevant(){

    let _self = this;

    let agendaToday = $('#agendaToday')
    let closestDateFound = false;
    let selected_festival_term_id = $('.festival-filter.tag-active').data('term_id');

    if(_self.debug) console.log('Agenda checkIfTodayIsRelevant festival selected?', selected_festival_term_id);
    if(selected_festival_term_id != 0) {
      // there is a festival selected, no year-month in this view, check if today's date might be relevant
      let dateToday = agendaToday.attr('data-value');
      let targetDate = $('#date-'+_self.dateToScroll);
      if(targetDate.length) {
        // today's date is in festival list of dates
        closestDateFound = true;
      } else {
        let dateToScrollNum = parseInt(dateToday.replace(/-/g, ''));
        $('.t-agenda__events-list-date').each(function(i,date){
          let dateId = $(date).attr("id");
          let dateToTest = dateId.replace(/[date-]/gm, '');
          if(_self.debug) console.log('Agenda checkIfTodayIsRelevant dateToTest ', dateToTest);
          if(dateToTest > dateToScrollNum) {
            // _self.dateToScroll = dateId.replace('date-', '');
            if(_self.debug) console.log('Agenda checkIfTodayIsRelevant date is closest after, I think. _self.dateToScroll', dateToTest);
            closestDateFound = true;
            // _self.scrollToDate();
            return false;
          }
        });

      }
    } else {
      // no festival selected -> display today button
      closestDateFound = true;
    }

    if(_self.debug) console.log('Agenda checkIfTodayIsRelevant closestDateFound?', closestDateFound);
    agendaToday.toggleClass('hidden', !closestDateFound);


  }

  scrollToDate(){

    let _self = this;

    if(_self.debug) console.log('Agenda scrollToDate _self.dateToScroll', _self.dateToScroll);
    let targetDate = $('#date-'+_self.dateToScroll);
    if(targetDate.length) {
      if(_self.debug) console.log('Agenda scrollToDate targetDate found, scroll to it');
      window.scrollTo({
        top: targetDate.offset().top-$('.o-header').height()-20,
        behavior: 'smooth'
      });
    } else {
      // OR get closest date just after?
      let dateToScrollNum = parseInt(_self.dateToScroll.replace(/-/g, ''));
      if(_self.debug) console.log('Agenda scrollToDate targetDate not found', _self.dateToScroll, dateToScrollNum);
      $('.t-agenda__events-list-date').each(function(i,date){
        let dateId = $(date).attr("id");
        let dateToTest = dateId.replace(/[date-]/gm, '');
        if(_self.debug) console.log('Agenda scrollToDate dateToTest ', dateToTest);
        if(dateToTest > dateToScrollNum) {
          _self.dateToScroll = dateId.replace('date-', '');
          if(_self.debug) console.log('Agenda scrollToDate date is closest after, I think. _self.dateToScroll', _self.dateToScroll);
          _self.scrollToDate();
          return false;
        }
      });

    }
    _self.dateToScroll = null;
  }


  sortRepresentationByFirstHour(){
    // Reorder Representation In Same Day By Hours
    $('.t-agenda__events-list-events').each(function(){
      $(this).find('.t-agenda__events-list-item').sort(function (a, b) {
        return +a.dataset.first_hour - +b.dataset.first_hour;
      }).appendTo( $(this) );
    });
  }

  resizeTitles(){

    let _self = this;
    let targetNumLines = window.innerWidth < 768 ? 4 : 2;
    let step = 1;

    this.titles.each(function(i,title){
      //reset title font sizes
      $(title).css('font-size', '');

      let titleLineHeight = parseFloat($(title).css('line-height'));
      let titleFontSize = parseFloat($(title).css('font-size'));
      let titleHeight = $(title).height();
      let targetHeight = Math.ceil(targetNumLines * titleLineHeight);
      if(_self.debug) console.log($(title).html(), 'titleHeight', titleHeight, 'titleLineHeight', titleLineHeight, 'titleFontSize', titleFontSize, 'targetHeight', targetHeight);

      // //if is greater than target number of lines
      if(titleHeight > targetHeight) {
        // reduce font size until not overflown
        while (titleHeight > targetHeight) {
          titleFontSize = parseFloat($(title).css('font-size'));
          $(title).css('font-size', (titleFontSize - step) + 'px');
          titleLineHeight = parseFloat($(title).css('line-height'));
          targetHeight = Math.ceil(targetNumLines * titleLineHeight);
          titleHeight = $(title).height();
          if(_self.debug) console.log($(title).html(), 'titleHeight', titleHeight, 'titleLineHeight', titleLineHeight, 'titleFontSize', titleFontSize, 'targetHeight', targetHeight);
        }
      }
    });
  }

  _resize() {

    this.resizeTitles();

  }

}

export { Agenda };
